import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from 'docz';
import { FiMenu, FiX, FiChevronUp } from 'react-icons/fi';
import { Flex } from '../Flex';
import { Box } from '../Box';
import { Button } from '../Button';
import { Expandable, ExpandableToggle, ExpandableBody } from '../Expandable';
import { HeartLoader } from '../Loader';
import { Overlay } from '.';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const OverlayContent = makeShortcode("OverlayContent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "overlay"
    }}>{`Overlay`}</h1>
    <p>{`Overlay component to rendering an overlay either within an element or covering the entire screen.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Overlay} mdxType="Props" />
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`position`}</inlineCode>{`: defaults to `}<inlineCode parentName="li">{`fixed`}</inlineCode>{` for a full page overlay`}</li>
    </ul>
    <h3 {...{
      "id": "contained-overlay"
    }}>{`Contained overlay`}</h3>
    <p>{`When passing `}<inlineCode parentName="p">{`position="absolute"`}</inlineCode>{` it will fit to the parent. This is useful for, for example, blocking a form and showing a loading animation.`}</p>
    <Playground __position={1} __code={'() => {\n  const OverlayContent = () => (\n    <Flex\n      width=\"100%\"\n      height=\"100%\"\n      justifyContent=\"center\"\n      alignItems=\"center\"\n    >\n      <HeartLoader size={40} context=\"shadower\" />\n    </Flex>\n  )\n  return (\n    <Box position=\"relative\" width=\"700px\" height=\"200px\" bg=\"neutral.base\">\n      {/** margin=\"base\" only to show it fits to the parent container */}\n      <Overlay position=\"absolute\" margin=\"base\">\n        <OverlayContent />\n      </Overlay>\n    </Box>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      FiMenu,
      FiX,
      FiChevronUp,
      Flex,
      Box,
      Button,
      Expandable,
      ExpandableToggle,
      ExpandableBody,
      HeartLoader,
      Overlay,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const OverlayContent = () => <Flex width="100%" height="100%" justifyContent="center" alignItems="center" mdxType="Flex">
        <HeartLoader size={40} context="shadower" mdxType="HeartLoader" />
      </Flex>;

        return <Box position="relative" width="700px" height="200px" bg="neutral.base" mdxType="Box">
        {
            /** margin="base" only to show it fits to the parent container */
          }
        <Overlay position="absolute" margin="base" mdxType="Overlay">
          <OverlayContent mdxType="OverlayContent" />
        </Overlay>
      </Box>;
      }}
    </Playground>
    <h3 {...{
      "id": "page-overlay"
    }}>{`Page overlay`}</h3>
    <p>{`We use `}<inlineCode parentName="p">{`Expandable`}</inlineCode>{` to allow for toggling on and off in this case.`}</p>
    <Playground __position={2} __code={'() => {\n  const OverlayContent = () => (\n    <Flex\n      width=\"100%\"\n      height=\"100%\"\n      justifyContent=\"center\"\n      alignItems=\"center\"\n    >\n      <ExpandableToggle>\n        <Button context=\"neutral\">\n          <FiX /> Close page overlay\n        </Button>\n      </ExpandableToggle>\n    </Flex>\n  )\n  return (\n    <Expandable>\n      <ExpandableToggle>\n        <Button context=\"neutral\">Click to show page overlay</Button>\n      </ExpandableToggle>\n      <ExpandableBody>\n        <Overlay>\n          <OverlayContent />\n        </Overlay>\n      </ExpandableBody>\n    </Expandable>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      FiMenu,
      FiX,
      FiChevronUp,
      Flex,
      Box,
      Button,
      Expandable,
      ExpandableToggle,
      ExpandableBody,
      HeartLoader,
      Overlay,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const OverlayContent = () => <Flex width="100%" height="100%" justifyContent="center" alignItems="center" mdxType="Flex">
        <ExpandableToggle mdxType="ExpandableToggle">
          <Button context="neutral" mdxType="Button">
            <FiX mdxType="FiX" /> Close page overlay
          </Button>
        </ExpandableToggle>
      </Flex>;

        return <Expandable mdxType="Expandable">
        <ExpandableToggle mdxType="ExpandableToggle">
          <Button context="neutral" mdxType="Button">Click to show page overlay</Button>
        </ExpandableToggle>
        <ExpandableBody mdxType="ExpandableBody">
          <Overlay mdxType="Overlay">
            <OverlayContent mdxType="OverlayContent" />
          </Overlay>
        </ExpandableBody>
      </Expandable>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      